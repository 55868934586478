import { React, useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  Space,
  notification,
  AutoComplete,
  Upload,
  Divider,
} from "antd";
import {
  updateOrder,
  cancelOrderAtStore,
  cancelStorePickup,
  orderAtStore,
  storePickup,
  assignEngineer,
  assignPickup,
  searchStaff,
  assignDeliveryBoy,
  getAllProductList,
  API_BASE_PHOTO_URL,
  ccoResolved,
  cancelCcoResolved,
  ccoDelivered,
  cancelCcoDelivered,
  estiApproved,
  estiRejected,
} from "../AxiosApis/api";
import { CloseCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import PhotoGallery from "./photoGallery";
import { UserContext } from "./../../UserContext";

const EditOrder = ({
  fetchOrders,
  editOrderId,
  form,
  setLoading,
  setIsEditModalVisible,
  id,
  selectedOrder,
}) => {
  const { userDetails } = useContext(UserContext);
  const [staffList, setStaffList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectModal, setSelectModal] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [filteredProductNames, setFilteredProductNames] = useState([]);
  const [modelPhotos, setModelPhotos] = useState([]);
  const [serialPhotos, setSerialPhotos] = useState([]);
  const [descriptionPhotos, setDescriptionPhotos] = useState([]);
  const [previousBillPhotos, setPreviousBillPhotos] = useState([]);

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    const email = getEmailForFetch();
    if (email) {
      fetchProducts(email);
    }
  }, [userDetails]);

  const fetchProducts = async (email) => {
    try {
      const response = await getAllProductList({ email });
      const products = response.data.products.map((product) => ({
        value: product.name,
      }));
      setProductOptions(products);
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };

  const fetchStaffList = async (role, status) => {
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    try {
      const response = await searchStaff({ email: adminEmail, role });
      setStaffList(response.data.data);
      setSelectedStatus(status);
      setSelectModal(true);
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  const handleUpdateOrder = async (values) => {
    const formData = new FormData();

    formData.append("customerName", values.customerName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append("productName", values.productName);
    formData.append("productModel", values.productModel);
    formData.append("serialno", values.serialno);
    formData.append("serialnosize", values.serialnosize);
    formData.append("description", values.description);
    formData.append("address[fulladdress]", values.address.fulladdress);
    formData.append("billing[pickupCost]", values.billing.pickupCost);
    formData.append("billing[deliveryCost]", values.billing.deliveryCost);
    formData.append("billing[technicianCost]", values.billing.technicianCost);
    formData.append("billing[otherCost]", values.billing.otherCost);
    formData.append("billing[cgst]", values.billing.cgst);
    formData.append("billing[sgst]", values.billing.sgst);
    formData.append("billing[igst]", values.billing.igst);

    modelPhotos.forEach((photo) =>
      formData.append("modelphotos", photo.originFileObj)
    );
    serialPhotos.forEach((photo) =>
      formData.append("serialphotos", photo.originFileObj)
    );
    descriptionPhotos.forEach((photo) =>
      formData.append("descriptionphotos", photo.originFileObj)
    );
    previousBillPhotos.forEach((photo) =>
      formData.append("previousbillphotos", photo.originFileObj)
    );

    try {
      await updateOrder(id, formData);
      notification.success({
        message: "Success",
        description: "Order updated successfully",
      });
      fetchOrders();
      setIsEditModalVisible(false);
      form.resetFields();

      setModelPhotos([]);
      setSerialPhotos([]);
      setDescriptionPhotos([]);
      setPreviousBillPhotos([]);
    } catch (error) {
      console.error("Error updating order:", error);
      notification.error({
        message: "Error",
        description: "There was an error updating the order",
      });
    }
  };

  const renderAssignButtons = (status) => {
    switch (status) {
      case "pending":
        return (
          <Button
            type="primary"
            onClick={() => fetchStaffList("transport", status)}
          >
            Assign To Pickup
          </Button>
        );
      case "order_At_Store":
        return (
          <Button
            type="primary"
            onClick={() => fetchStaffList("engineer", status)}
          >
            Assign To Engineer
          </Button>
        );
      case "resolved":
      case "issue_reported":
        return (
          <Button
            type="primary"
            onClick={() => fetchStaffList("transport", status)}
          >
            Assign To Delivery
          </Button>
        );
      default:
        return null;
    }
  };
  const handleAssign = async (staffId, staffName) => {
    let payload = {};

    switch (selectedStatus) {
      case "pending":
        payload = {
          orderId: editOrderId,
          transportPersonId: staffId,
        };
        break;
      case "order_At_Store":
        payload = {
          orderId: editOrderId,
          engineerId: staffId,
        };
        break;
      case "resolved":
      case "issue_reported":
        payload = {
          orderId: editOrderId,
          transportPersonId: staffId,
        };
        break;
      default:
        return;
    }

    try {
      if (selectedStatus === "pending") {
        await assignPickup(payload);
      } else if (selectedStatus === "order_At_Store") {
        await assignEngineer(payload);
      } else if (
        selectedStatus === "resolved" ||
        selectedStatus === "issue_reported"
      ) {
        await assignDeliveryBoy(payload);
      }

      notification.success({
        message: "Success",
        description: `Assigned to ${staffName} successfully`,
      });
      fetchOrders();
    } catch (error) {
      console.error("Error assigning staff:", error);
      notification.error({
        message: "Error",
        description: "There was an error assigning the staff",
      });
    }
  };

  const handleMarkAtStore = async () => {
    setLoading(true);
    try {
      await orderAtStore({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order marked as 'At Store' successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error marking order as at store:", error);
      notification.error({
        message: "Error",
        description: "There was an error marking the order as 'At Store'",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelStorePickup = async () => {
    setLoading(true);
    try {
      await cancelStorePickup({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order cancelled at store pickup",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error canceling Order cancelled at store pickup:", error);
      notification.error({
        message: "Error",
        description:
          "There was an error canceling Order cancelled at store pickup",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStorePickup = async () => {
    setLoading(true);
    try {
      await storePickup({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order will pick at store",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error canceling Order will pick at store:", error);
      notification.error({
        message: "Error",
        description: "There was an error canceling Order will pick at store",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelOrderAtStore = async () => {
    setLoading(true);
    try {
      await cancelOrderAtStore({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order cancelled at store successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error canceling order at store:", error);
      notification.error({
        message: "Error",
        description: "There was an error canceling the order at store",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCcoResolved = async () => {
    setLoading(true);
    try {
      await ccoResolved({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order marked as 'Resolved' successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error Marking Resolved:", error);
      notification.error({
        message: "Error",
        description: "There was an error marking the order as Marking Resolved",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCancelCcoResolved = async () => {
    setLoading(true);
    try {
      await cancelCcoResolved({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order cancelled Marked Resolved successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error cancel Marking Resolved:", error);
      notification.error({
        message: "Error",
        description:
          "There was an error marking the order as 'cancel Marking Resolve'",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCcoDelivered = async () => {
    setLoading(true);
    try {
      await ccoDelivered({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order marked as 'Delivered' successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error marking Delivered:", error);
      notification.error({
        message: "Error",
        description: "There was an error marking the order as 'Delivered'",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCancelCcoDelivered = async () => {
    setLoading(true);
    try {
      await cancelCcoDelivered({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order marked as 'Cancel Delivered' successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error marking order Cancel Delivered:", error);
      notification.error({
        message: "Error",
        description:
          "There was an error marking the order as 'Cancel Delivered'",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleEstiApproved = async () => {
    setLoading(true);
    try {
      await estiApproved({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order Esti Approved successfully",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error Esti Approved:", error);
      notification.error({
        message: "Error",
        description: "There was an error marking the order as ' Esti Approved'",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleEstiRejected = async () => {
    setLoading(true);
    try {
      await estiRejected({ orderId: editOrderId });
      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Order Esti Rejected",
      });
      fetchOrders();
    } catch (error) {
      console.error("Error cancel Esti Rejected:", error);
      notification.error({
        message: "Error",
        description: "There was an error marking the order as 'Esti Rejected'",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleProductSearch = (value) => {
    if (value) {
      const filtered = productOptions.filter((product) =>
        product.value.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProductNames(filtered);
    } else {
      setFilteredProductNames([]);
    }
  };

  const validateProductName = (_, value) => {
    if (!value || productOptions.some((product) => product.value === value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Please select a valid brand name from the list!")
    );
  };

  const handleUpload =
    (setPhotos) =>
    ({ fileList }) => {
      setPhotos(fileList);
    };
  const styles = {
    row: {
      display: "flex",
      alignItems: "flex-start",
    },
    leftCol: {
      padding: "0 10px",
      flex: 1,
      borderRight: "1px solid #ccc",
    },
    rightCol: {
      padding: "0 10px",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", // Ensures content at the top and buttons at the bottom
      height: "100%", // Optional: Adjusts based on the modal height
    },
    dividerCol: {
      display: "flex",
      justifyContent: "center",
      width: "2%",
      alignItems: "stretch",
    },
    dividerLine: {
      width: "1px",
      height: "100%",
      backgroundColor: "black",
    },
  };

  return (
    <>
      <Row style={styles.row}>
        <Col style={styles.leftCol}>
          <Form form={form} layout="vertical" onFinish={handleUpdateOrder}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="customerName"
                  label="Customer Name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="Call Number">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    { required: true, message: "Please enter your number!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="productName"
                  label="Brand Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Correct Brand Name",
                    },
                    { validator: validateProductName },
                  ]}
                >
                  <AutoComplete
                    options={filteredProductNames}
                    onSearch={handleProductSearch}
                    placeholder="Enter Brand name"
                    filterOption={false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="productModel" label="Brand Model">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="modelphotos" label="Upload Brand Model Photos">
                  <Upload
                    listType="picture-card"
                    fileList={modelPhotos}
                    onChange={handleUpload(setModelPhotos)}
                    action="about:blank"
                    showUploadList={{
                      showRemoveIcon: true,
                      showPreviewIcon: false,
                    }}
                    beforeUpload={() => false}
                  >
                    {modelPhotos.length < 5 && <PlusOutlined />}
                  </Upload>
                  <PhotoGallery
                    photos={selectedOrder.modelphotos}
                    orderId={selectedOrder.orderId}
                    API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="serialno" label="Serial Number">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="serialnosize" label="Size">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="serialphotos"
                  label="Upload Serial Number Photos"
                >
                  <Upload
                    listType="picture-card"
                    fileList={serialPhotos}
                    onChange={handleUpload(setSerialPhotos)}
                    action="about:blank"
                    showUploadList={{
                      showRemoveIcon: true,
                      showPreviewIcon: false,
                    }}
                    beforeUpload={() => false}
                  >
                    {serialPhotos.length < 5 && <PlusOutlined />}
                  </Upload>
                  <PhotoGallery
                    photos={selectedOrder.serialphotos}
                    orderId={selectedOrder.orderId}
                    API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Issue"
                  rules={[
                    { required: true, message: "Please Enter The Issue" },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="descriptionphotos" label="Upload Issue Photos">
                  <Upload
                    listType="picture-card"
                    fileList={descriptionPhotos}
                    onChange={handleUpload(setDescriptionPhotos)}
                    action="about:blank"
                    showUploadList={{
                      showRemoveIcon: true,
                      showPreviewIcon: false,
                    }}
                    beforeUpload={() => false}
                  >
                    {descriptionPhotos.length < 5 && <PlusOutlined />}
                  </Upload>
                  <PhotoGallery
                    photos={selectedOrder.descriptionphotos}
                    orderId={selectedOrder.orderId}
                    API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name={["address", "fulladdress"]}
                  label="Full Address"
                  rules={[
                    { required: true, message: "Please enter Full Address" },
                  ]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="previousbillphotos"
                  label="Upload Previous Bill Photos"
                >
                  <Upload
                    listType="picture-card"
                    multiple={true}
                    fileList={previousBillPhotos}
                    onChange={handleUpload(setPreviousBillPhotos)}
                    action="about:blank"
                    showUploadList={{
                      showRemoveIcon: true,
                      showPreviewIcon: false,
                    }}
                    beforeUpload={() => false}
                    accept=".pdf"
                  >
                    {previousBillPhotos.length < 5 && <PlusOutlined />}
                  </Upload>
                  <PhotoGallery
                    photos={selectedOrder.previousbillphotos}
                    orderId={selectedOrder.orderId}
                    API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={["billing", "pickupCost"]}
                  label=" Pickup Charges"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["billing", "deliveryCost"]}
                  label="Delivery Charges"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={["billing", "technicianCost"]}
                  label="Labour Charges"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["billing", "otherCost"]}
                  label="Spare Charges"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name={["billing", "cgst"]} label=" CGST">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={["billing", "sgst"]} label="SGST">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name={["billing", "igst"]} label="IGST">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ borderColor: "#333" }} />
            <h2>Total Charges: ₹ {selectedOrder.billing.totalCost}</h2>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Button type="primary" htmlType="submit">
                    Update Order
                  </Button>
                </Col>

                <Col span={12} style={{ textAlign: "end" }}>
                  {renderAssignButtons(form.getFieldValue("status"))}
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        <Col style={styles.dividerCol}>
          <div style={styles.dividerLine}></div>
        </Col>
        <Col style={styles.rightCol}>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <p>Order is arrived at Store or Cancel Arrived at store:</p>
            <Col xs={24} sm={24} md={{ span: 8, offset: 0 }}>
              <Button
                type="primary"
                onClick={handleMarkAtStore}
                disabled={[
                  // "assign_pick_up",
                  // "picked_up",
                  "order_At_Store",
                  "in_progress",
                  "issue_reported",
                  "resolved",
                  "store_pick_up",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                Mark At Store
              </Button>
            </Col>

            <Col xs={24} sm={24} md={{ span: 8, offset: 8 }}>
              <Button
                type="primary"
                onClick={handleCancelOrderAtStore}
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "in_progress",
                  "issue_reported",
                  "resolved",
                  "store_pick_up",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                <CloseCircleTwoTone /> At Store
              </Button>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 16 }}>
            <p>Order is resolved or Cancel order is resolved by CCO:</p>
            <Col xs={24} sm={24} md={{ span: 8, offset: 0 }}>
              <Button
                onClick={handleCcoResolved}
                style={{ marginRight: 8 }}
                type="primary"
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "resolved",
                  "issue_reported",
                  "store_pick_up",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                Mark Resolved
              </Button>
            </Col>

            <Col xs={24} sm={24} md={{ span: 8, offset: 8 }}>
              <Button
                onClick={handleCancelCcoResolved}
                type="primary"
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "order_At_Store",
                  "in_progress",
                  "issue_reported",
                  "store_pick_up",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                <CloseCircleTwoTone />
                Resolved
              </Button>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <p>Order to Delivered or Cancel to Delivered by CCO:</p>
            <Col xs={24} sm={24} md={{ span: 8, offset: 0 }}>
              <Button
                onClick={handleCcoDelivered}
                style={{ marginRight: 8 }}
                type="primary"
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "order_At_Store",
                  "in_progress",
                  "issue_reported",
                  "store_pick_up",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                Mark Delivered
              </Button>
            </Col>

            <Col xs={24} sm={24} md={{ span: 8, offset: 8 }}>
              <Button
                onClick={handleCancelCcoDelivered}
                type="primary"
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "order_At_Store",
                  "in_progress",
                  "issue_reported",
                  "resolved",
                  "store_pick_up",
                  "assign_delivery",
                ].includes(form.getFieldValue("status"))}
              >
                <CloseCircleTwoTone />
                Delivered
              </Button>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 16 }}>
            <p>Order to Customer Pickup or Cancel Customer Pickup :</p>
            <Col xs={24} sm={24} md={{ span: 8, offset: 0 }}>
              <Button
                onClick={handleStorePickup}
                style={{ marginRight: 8 }}
                type="primary"
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "order_At_Store",
                  "in_progress",
                  "issue_reported",
                  "store_pick_up",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                Mark Store PickUp
              </Button>
            </Col>

            <Col xs={24} sm={24} md={{ span: 8, offset: 8 }}>
              <Button
                onClick={handleCancelStorePickup}
                type="primary"
                disabled={[
                  "pending",
                  "assign_pick_up",
                  "picked_up",
                  "order_At_Store",
                  "in_progress",
                  "issue_reported",
                  "resolved",
                  "assign_delivery",
                  "delivered",
                ].includes(form.getFieldValue("status"))}
              >
                <CloseCircleTwoTone /> Store Pickup
              </Button>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 16 }}>
            <p> Order Estimated Approved b or Order Estimated Cancelled:</p>
            <Col xs={24} sm={24} md={{ span: 8, offset: 0 }}>
              <Button
                onClick={handleEstiApproved}
                style={{ marginRight: 8 }}
                type="primary"
                disabled={["esti_Approved"].includes(
                  form.getFieldValue("estimationstatus")
                )}
              >
                Esti Approved
              </Button>
            </Col>

            <Col xs={24} sm={24} md={{ span: 8, offset: 8 }}>
              <Button
                onClick={handleEstiRejected}
                type="primary"
                disabled={["esti_Rejected"].includes(
                  form.getFieldValue("estimationstatus")
                )}
              >
                <CloseCircleTwoTone />
                Esti Rejected
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="Select Staff"
        visible={selectModal}
        onCancel={() => setSelectModal(false)}
        footer={null}
      >
        <ul>
          {staffList.map((staff) => (
            <li
              key={staff._id}
              onClick={async () => {
                await handleAssign(staff._id, staff.name);
                setSelectModal(false);
              }}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              {staff.name}
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default EditOrder;

import { React, useState } from "react";
import { Button, Col, message, Row, Tag } from "antd";
import {
  API_BASE_PHOTO_URL,
  generateInvoice,
  getQrCode,
} from "../AxiosApis/api";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PhotoGallery from "./photoGallery";
import { DownloadOutlined } from "@ant-design/icons";

const ViewOrder = ({
  selectedOrder,
  getStatusColor,
  handleDownloadOrder,
  handleEstiDownloadOrder,
}) => {
  const [qrCode, setQrCode] = useState("");
  const [isQrGenerated, setIsQrGenerated] = useState(false);
  const [invoiceURL, setInvoiceURL] = useState("");
  const handleGenerateQRCode = async () => {
    try {
      const response = await getQrCode(selectedOrder._id);
      setQrCode(response?.data?.qrCode);
      setIsQrGenerated(true);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("qrDetailsSection");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("QRCode_OrderDetails.pdf");
    });
  };

  const handlePrintQRCode = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body {
            margin: 0;
            padding: 0;
            background-color: #fff;
          }
          .container {
            width: 100%;
            height: auto; /* Let the height adjust based on the content */
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-family: Arial, sans-serif;
            font-size: 28px;
            padding: 2cm;
            box-sizing: border-box;
          }
          .left {
            flex: 1;
            padding-right: 2cm;
          }
          .right {
            width: 18cm; /* Increased the width of the QR code container */
            height: 18cm; /* Increased the height of the QR code container */
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .right img {
            width: 100%;  /* The image will take up the full width of the container */
            height: 100%; /* The image will take up the full height of the container */
          }
          .order-details p {
            margin: 20px 0;
          }
          /* Increased font size for the specific paragraphs */
          .order-details p.large-text {
            font-size: 60px; /* Increase font size */
          }
          .order-title {
            font-size: 140px; /* Title font size remains the same */
            font-weight: bold;
            margin-bottom: 15px;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="left order-details">
            <p class="order-title">${selectedOrder.orderType}</p>
            <p class="large-text"><strong>Order ID:</strong> ${
              selectedOrder.orderId
            }</p>
            <p class="large-text"><strong>${
              selectedOrder.orderType === "B2C"
                ? "Customer Name"
                : "Vendor Name"
            }:</strong> ${selectedOrder.name}</p>
            <p class="large-text"><strong>Model:</strong> ${
              selectedOrder.productModel
            }</p>
            <p class="large-text"><strong>Brand:</strong> ${
              selectedOrder.productName
            }</p>
            <p class="large-text"><strong>Serial No:</strong> ${
              selectedOrder.serialno
            }</p>
            <p class="large-text"><strong>Issue:</strong> ${
              selectedOrder.description
            }</p>
            <p class="large-text"><strong>Call No:</strong> ${
              selectedOrder.email
            }</p>
          </div>
          <div class="right">
            <img src="${qrCode}" alt="QR Code" />
          </div>
        </div>
      </body>
    </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const handleGenerateInvoice = async () => {
    try {
      const response = await generateInvoice(selectedOrder.orderId);
      setInvoiceURL(response.data);
      message.success("Invoice generated successfully!");
    } catch (error) {
      console.error("Error generating invoice:", error);
      message.error("Failed to generate invoice");
    }
  };

  const handleDownloadInvoice = () => {
    if (!invoiceURL) {
      message.error("No invoice generated!");
      return;
    }

    const blob = new Blob([invoiceURL], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `Invoice_${selectedOrder._id}.pdf`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handlePrintInvoice = () => {
    if (!invoiceURL) {
      message.error("No invoice generated!");
      return;
    }

    // Create a Blob URL for the PDF
    const blob = new Blob([invoiceURL], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    // Open a new window with the PDF and trigger print
    const printWindow = window.open(url, "_blank");
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <>
      {" "}
      {selectedOrder && (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Button type="primary" onClick={handleGenerateQRCode}>
                Generate QR Code
              </Button>
            </Col>

            <Col span={24} style={{ textAlign: "right" }}>
              {isQrGenerated && (
                <div>
                  <Row
                    id="qrDetailsSection"
                    style={{ width: "100%", marginTop: "10px" }}
                    gutter={[8, 0]}
                  >
                    <Col span={10}>
                      {qrCode && (
                        <img
                          src={qrCode}
                          alt="QR Code"
                          style={{
                            width: "100px",
                            height: "100px",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </Col>
                    <Col span={14} style={{ textAlign: "initial" }}>
                      <p style={{ margin: 0 }}>
                        <strong>Name:</strong> {selectedOrder.name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <strong>Order ID:</strong> {selectedOrder.orderId}
                      </p>
                      <p style={{ margin: 0 }}>
                        <strong>Phone Number:</strong>{" "}
                        {selectedOrder.phoneNumber}
                      </p>
                    </Col>
                  </Row>

                  <Row
                    style={{ width: "100%", marginTop: "10px" }}
                    gutter={[8, 0]}
                  >
                    <Col span={12}>
                      <Button
                        onClick={handleDownload}
                        disabled={!qrCode}
                        type="primary"
                        ghost
                        style={{ width: "100%" }}
                      >
                        Download
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        onClick={handlePrintQRCode}
                        disabled={!qrCode}
                        type="primary"
                        style={{ width: "100%" }}
                      >
                        Print
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>

          <p>
            <strong>Customer Name:</strong> {selectedOrder.customerName}
          </p>
          <p>
            <strong>Call Number:</strong> {selectedOrder.email}
          </p>
          <p>
            <strong>Order ID:</strong> {selectedOrder.orderId}
          </p>
          <p>
            <strong>Phone Number:</strong> {selectedOrder.phoneNumber}
          </p>
          <p>
            <strong>Product Name:</strong> {selectedOrder.productName}
          </p>
          <p>
            <strong>Product Model:</strong> {selectedOrder.productModel}
            <PhotoGallery
              photos={selectedOrder.modelphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Serial Number:</strong> {selectedOrder.serialno}
            <PhotoGallery
              photos={selectedOrder.serialphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Issue:</strong> {selectedOrder.description}
            <PhotoGallery
              photos={selectedOrder.descriptionphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Previous Bills:</strong>
            <PhotoGallery
              photos={selectedOrder.previousbillphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Status:</strong>{" "}
            <Tag color={getStatusColor(selectedOrder.status)}>
              {selectedOrder.status.toUpperCase()}
            </Tag>
          </p>
          <p>
            <strong>Address:</strong> {selectedOrder.address.fulladdress}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {new Date(selectedOrder.createdAt).toLocaleString()}
          </p>
          <p>
            <strong>Last Modified:</strong>{" "}
            {new Date(selectedOrder.lastModified).toLocaleString()}
          </p>
          <h2>Estimation & worksheet</h2>

          <Row justify="space-between" gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="primary"
                onClick={() => handleDownloadOrder(selectedOrder)}
                block
              >
                <DownloadOutlined /> Job Sheet
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Button
                type="primary"
                ghost
                onClick={() => handleEstiDownloadOrder(selectedOrder)}
                block
              >
                <DownloadOutlined /> Estimation
              </Button>
            </Col>
          </Row>

          <h2>Generate Bill</h2>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={8} md={8}>
              <Button type="primary" onClick={handleGenerateInvoice}>
                Generate Bill
              </Button>
            </Col>

            <Col xs={24} sm={8} md={8}>
              <Button
                type="primary"
                onClick={handlePrintInvoice}
                disabled={!invoiceURL}
              >
                Print Bill
              </Button>
            </Col>

            <Col xs={24} sm={8} md={8}>
              <Button
                type="primary"
                onClick={handleDownloadInvoice}
                disabled={!invoiceURL}
              >
                Download Bill
              </Button>
            </Col>
          </Row>

          <h2>Photos At Pickup</h2>
          <PhotoGallery
            photos={selectedOrder.photos}
            orderId={selectedOrder.orderId}
            API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
          />

          <h2>Photos At Resolved</h2>
          <PhotoGallery
            photos={selectedOrder.resolvedphotos}
            orderId={selectedOrder.orderId}
            API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
          />
          <h2>Order History</h2>
          <div>
            <p>
              <strong>Assigned to Pickup At:</strong>{" "}
              {selectedOrder.assignPickupTime
                ? new Date(selectedOrder.assignPickupTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Pickup Person:</strong>{" "}
              {selectedOrder?.pickupPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Picked Up At:</strong>{" "}
              {selectedOrder.pickupTime
                ? new Date(selectedOrder.pickupTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Order At Store:</strong>{" "}
              {selectedOrder.storeDeliveryTime
                ? new Date(selectedOrder.storeDeliveryTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Assigned to Engineer At:</strong>{" "}
              {selectedOrder.assignEngineerTime
                ? new Date(selectedOrder.assignEngineerTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Engineer Name:</strong>
              {selectedOrder?.technician?.name || "N/A"}
            </p>
            <p>
              <strong>Resolved At:</strong>{" "}
              {selectedOrder.resolvedTime
                ? new Date(selectedOrder.resolvedTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Issue Reported At:</strong>{" "}
              {selectedOrder.issue_ReportedTime
                ? new Date(selectedOrder.issue_ReportedTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Assigned to Delivery At:</strong>{" "}
              {selectedOrder.deliveryAssignedTime
                ? new Date(selectedOrder.deliveryAssignedTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Delivery Person: </strong>
              {selectedOrder?.deliveryPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Product Delivered At:</strong>{" "}
              {selectedOrder.deliveredTime
                ? new Date(selectedOrder.deliveredTime).toLocaleString()
                : "N/A"}
            </p>
          </div>

          <h2>Cancellation Details</h2>
          <div>
            <p>
              <strong>Cancelled by:</strong>{" "}
              {selectedOrder?.cancelledPickupPerson?.name || "N/A"}
            </p>

            <p>
              <strong>Reason for Cancellation:</strong>{" "}
              {selectedOrder?.reasonforCancelPickUp || "N/A"}
            </p>
            <p>
              <strong>Cancelled Time:</strong>{" "}
              {selectedOrder?.cancelAssignPickUpTime
                ? new Date(
                    selectedOrder.cancelAssignPickUpTime
                  ).toLocaleString()
                : "N/A"}
            </p>

            <p>
              <strong>Cancelled by Engineer:</strong>{" "}
              {selectedOrder.cancelledEngineerPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Reason for Engineer Cancellation:</strong>{" "}
              {selectedOrder.reasonForCancelEngineer || "N/A"}
            </p>
            <p>
              <strong>Engineer Cancelled At:</strong>{" "}
              {selectedOrder.cancelAssignEngineerTime
                ? new Date(
                    selectedOrder.cancelAssignEngineerTime
                  ).toLocaleString()
                : "N/A"}
            </p>

            <p>
              <strong>Cancelled by Delivery Person:</strong>{" "}
              {selectedOrder.cancelledDeliveryPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Reason for Delivery Cancellation:</strong>{" "}
              {selectedOrder.reasonForCancelDelivery || "N/A"}
            </p>
            <p>
              <strong>Delivery Cancelled At:</strong>{" "}
              {selectedOrder.cancelAssignDeliveryTime
                ? new Date(
                    selectedOrder.cancelAssignDeliveryTime
                  ).toLocaleString()
                : "N/A"}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewOrder;

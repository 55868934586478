import React, { useState, useEffect, useContext } from "react";
import AppLayout from "./../../layout/Layout";
import {
  Button,
  Col,
  Row,
  Table,
  Modal,
  Form,
  Input,
  notification,
  Popconfirm,
  Space,
  AutoComplete,
  Radio,
  Tag,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import CreateOrder from "./createOrders";
import EditOrder from "./EditOrder";
import ViewOrder from "./ViewOrder";
import { deleteOrder, getAllOrders, searchOrder } from "../AxiosApis/api";
import CreateB2BOrder from "./createB2BOrders";
import { UserContext } from "../../UserContext";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Order = () => {
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [editOrderId, setEditOrderId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchType, setSearchType] = useState("customerName");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [viewOrderModalVisible, setViewOrderModalVisible] = useState(false);
  const [orderType, setOrderType] = useState("B2B");
  const [currentFilter, setCurrentFilter] = useState(null);

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    const email = getEmailForFetch();
    if (email) {
      fetchOrders(email);
    }
  }, [userDetails]);

  const fetchOrders = async (email, status = null) => {
    setLoading(true);
    try {
      const params = { email };
      if (status) {
        params.status = status;
      }
      const response = await getAllOrders(params);

      const sortedOrders = response.data.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrders(sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };
  const exportToExcel = () => {
    const ordersToExport = currentFilter
      ? orders.filter((order) => order.status === currentFilter)
      : orders;

    const filteredOrders = ordersToExport.map(
      ({
        address,
        billing,
        name,
        email,
        phoneNumber,
        productName,
        productModel,
        serialno,
        serialnosize,
        description,
        status,
        createdAt,
        lastModified,
        orderId,
        customerName,
      }) => ({
        orderId,
        name,
        customerName: customerName || null,
        callNumber: email,
        phoneNumber,
        address: address.fulladdress,
        productName,
        productModel,
        SerialNumber: serialno,
        Size: serialnosize,
        Issue: description,
        status,
        billingPickupCost: billing.pickupCost,
        billingTechnicianCost: billing.technicianCost,
        billingDeliveryCost: billing.deliveryCost,
        billingOtherCost: billing.otherCost,
        billingTotalCost: billing.totalCost,
        billingCgst: billing.cgst,
        billingSgst: billing.sgst,
        billingIgst: billing.igst,
        createdAt,
        lastModified,
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(filteredOrders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    XLSX.writeFile(workbook, "Orders.xlsx");
  };

  const handleDownloadOrder = async (order) => {
    try {
      const response = await fetch("/downloadorder.html");
      let htmlContent = await response.text();

      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = htmlContent;
      document.body.appendChild(tempContainer);

      tempContainer.querySelector("#customerName").textContent =
        order.customerName || "N/A";
      tempContainer.querySelector("#name").textContent = order.name || "N/A";
      tempContainer.querySelector("#orderId").textContent =
        order.orderId || "N/A";
      tempContainer.querySelector("#phoneNumber").textContent =
        order.phoneNumber || "N/A";
      tempContainer.querySelector("#email").textContent = order.email || "N/A";
      tempContainer.querySelector("#address\\.fulladdress").textContent =
        order.address.fulladdress || "N/A";
      tempContainer.querySelector("#orderType").textContent =
        order.orderType || "N/A";
      tempContainer.querySelector("#productName").textContent =
        order.productName || "N/A";
      tempContainer.querySelector("#productModel").textContent =
        order.productModel || "N/A";
      tempContainer.querySelector("#serialno").textContent =
        order.serialno || "N/A";
      tempContainer.querySelector("#description").textContent =
        order.description || "N/A";

      const canvas = await html2canvas(tempContainer);
      const doc = new jsPDF();
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = doc.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      document.body.removeChild(tempContainer);

      doc.save(`Order_${order.orderId}.pdf`);
    } catch (error) {
      console.error("Error fetching or rendering HTML:", error);
    }
  };

  const handleEstiDownloadOrder = async (order) => {
    try {
      const response = await fetch("/estimation.html");
      let htmlContent = await response.text();

      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = htmlContent;
      document.body.appendChild(tempContainer);

      tempContainer.querySelector("#customerName").textContent =
        order.customerName || "N/A";
      tempContainer.querySelector("#name").textContent = order.name || "N/A";
      tempContainer.querySelector("#orderId").textContent =
        order.orderId || "N/A";
      tempContainer.querySelector("#phoneNumber").textContent =
        order.phoneNumber || "N/A";
      tempContainer.querySelector("#email").textContent = order.email || "N/A";
      tempContainer.querySelector("#address\\.fulladdress").textContent =
        order.address.fulladdress || "N/A";
      tempContainer.querySelector("#orderType").textContent =
        order.orderType || "N/A";
      tempContainer.querySelector("#description").textContent =
        order.description || "N/A";
      tempContainer.querySelector("#productName").textContent =
        order.productName || "N/A";
      tempContainer.querySelector("#productModel").textContent =
        order.productModel || "N/A";
      tempContainer.querySelector("#billing\\.pickupCost").textContent =
        order.billing.pickupCost || "N/A";
      tempContainer.querySelector("#billing\\.technicianCost").textContent =
        order.billing.technicianCost || "N/A";
      tempContainer.querySelector("#billing\\.deliveryCost").textContent =
        order.billing.deliveryCost || "N/A";
      tempContainer.querySelector("#billing\\.otherCost").textContent =
        order.billing.otherCost || "N/A";
      tempContainer.querySelector("#billing\\.totalCost").innerHTML = `₹ ${
        order.billing.totalCost || "N/A"
      }`;
      tempContainer.querySelector("#billing\\.sgst").textContent =
        order.billing.sgst || "N/A";
      tempContainer.querySelector("#billing\\.cgst").textContent =
        order.billing.cgst || "N/A";
      tempContainer.querySelector("#billing\\.igst").textContent =
        order.billing.igst || "N/A";

      const canvas = await html2canvas(tempContainer);
      const doc = new jsPDF();
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = doc.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      document.body.removeChild(tempContainer);

      doc.save(`Estimation_${order.orderId}.pdf`);
    } catch (error) {
      console.error("Error fetching or rendering HTML:", error);
    }
  };

  const handleDeleteOrder = async (id) => {
    const email = getEmailForFetch();
    setLoading(true);
    try {
      await deleteOrder(id);
      notification.success({
        message: "Success",
        description: "Order deleted successfully",
      });
      fetchOrders(email);
    } catch (error) {
      console.error("Error deleting order:", error);
      notification.error({
        message: "Error",
        description: "There was an error deleting the order",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (order) => {
    setId(order._id);
    setEditOrderId(order.orderId);
    form.setFieldsValue(order);
    setIsEditModalVisible(true);
    setSelectedOrder(order);
  };

  const handleSearch = async (value) => {
    const email = getEmailForFetch();
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    setSearchText(value);
    if (value) {
      try {
        const response = await searchOrder({
          email: adminEmail,
          [searchType]: value,
        });
        const options = response.data.data.orders.map((order) => {
          let optionValue;

          if (searchType === "customerName") {
            optionValue = order.customerName;
          } else if (searchType === "orderId") {
            optionValue = order.orderId;
          } else if (searchType === "phoneNumber") {
            optionValue = order.phoneNumber;
          }

          return { value: optionValue };
        });

        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchOrders(email);
    }
  };

  const handleSelect = async (value) => {
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    try {
      const response = await searchOrder({
        email: adminEmail,
        [searchType]: value,
      });
      setOrders(response.data.data.orders);
    } catch (error) {
      console.error("Error fetching selected products:", error);
    }
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setViewOrderModalVisible(true);
  };

  const handleCloseViewOrderModal = () => {
    setViewOrderModalVisible(false);
    setSelectedOrder(null);
  };

  const handleCreateOrderB2B = () => {
    setIsModalVisible(true);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "orange";
      case "assign_pick_up":
        return "geekblue";
      case "picked_up":
        return "green";
      case "order_At_Store":
        return "volcano";
      case "in_progress":
        return "purple";
      case "issue_reported":
        return "pink";
      case "resolved":
        return "cyan";
      case "assign_delivery":
        return "gold";
      case "delivered":
        return "lime";
      case "esti_Pending":
        return "magenta";
      case "esti_Approved":
        return "blue";
      case "esti_Rejected":
        return "red";
      default:
        return "grey";
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const selectedStatus = filters.status ? filters.status[0] : null;
    const selectedEstimationStatus = filters.estimationstatus
      ? filters.estimationstatus[0]
      : null;

    setCurrentFilter({
      status: selectedStatus,
      estimationstatus: selectedEstimationStatus,
    });

    const email = getEmailForFetch();
    fetchOrders(email, {
      status: selectedStatus,
      estimationstatus: selectedEstimationStatus,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "customerName",
      render: (text, record) => (
        <span style={{ color: record.orderType === "B2B" ? "green" : "blue" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      width: 50,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 50,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 90,
    },
    {
      title: "Product Model",
      dataIndex: "productModel",
      key: "productModel",
      width: 90,
    },
    {
      title: "Serial Number",
      dataIndex: "serialno",
      key: "serialno",
      width: 50,
    },
    {
      title: "Size",
      dataIndex: "serialnosize",
      key: "serialnosize",
      width: 50,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Assign Pick Up", value: "assign_pick_up" },
        { text: "Picked Up", value: "picked_up" },
        { text: "Order At Store", value: "order_At_Store" },
        { text: "In Progress", value: "in_progress" },
        { text: "Issue Reported", value: "issue_reported" },
        { text: "Resolved", value: "resolved" },
        { text: "Store Pick Up", value: "store_pick_up" },
        { text: "Assign Delivery", value: "assign_delivery" },
        { text: "Delivered", value: "delivered" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        return <Tag color={getStatusColor(status)}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Esti Status",
      dataIndex: "estimationstatus",
      key: "estimationstatus",
      filters: [
        { text: "Esti Pending", value: "esti_Pending" },
        { text: "Approved", value: "esti_Approved" },
        { text: "Rejected", value: "esti_Rejected" },
      ],
      onFilter: (value, record) => record.estimationstatus === value,
      render: (status) => {
        return <Tag color={getStatusColor(status)}>{status.toUpperCase()}</Tag>;
      },
      width: 50,
    },
    {
      title: "Actions",
      key: "actions",
      width: 50,
      render: (text, record) => (
        <Space size="large">
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleViewOrder(record)}
          />
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleEditClick(record)}
          />
          <Popconfirm
            title="Are you sure to delete this order?"
            onConfirm={() => handleDeleteOrder(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col xs={24} sm={24} md={{ span: 8, offset: 0 }}>
          <Radio.Group
            onChange={(e) => setSearchType(e.target.value)}
            value={searchType}
            style={{ marginBottom: "16px" }}
          >
            <Radio.Button value="customerName">Name</Radio.Button>
            <Radio.Button value="orderId">Order ID</Radio.Button>
            <Radio.Button value="phoneNumber">Phone Number</Radio.Button>
          </Radio.Group>

          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by name or order ID"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={{ span: 8, offset: 8 }}
          style={{ textAlign: "end", marginTop: "10px" }}
        >
          <Radio.Group
            onChange={(e) => setOrderType(e.target.value)}
            value={orderType}
            style={{ marginBottom: "10px" }}
          >
            <Radio value="B2B">B2B</Radio>
            <Radio value="B2C">B2C</Radio>
          </Radio.Group>

          <div style={{ marginTop: "10px" }}>
            <Button type="primary" onClick={handleCreateOrderB2B}>
              Create Order <PlusOutlined />
            </Button>

            <Button
              type="primary"
              ghost
              onClick={exportToExcel}
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              Download Orders <DownloadOutlined />
            </Button>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={orders}
            rowKey="_id"
            scroll={{ x: "max-content" }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>

      {orderType === "B2B" ? (
        <Modal
          title="Create B2B Order"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <CreateOrder
            fetchOrders={() => fetchOrders(getEmailForFetch())}
            setIsModalVisible={setIsModalVisible}
            orderType="B2B"
          />
        </Modal>
      ) : (
        <Modal
          title="Create B2C Order"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <CreateB2BOrder
            fetchOrders={() => fetchOrders(getEmailForFetch())}
            setIsModalVisible={setIsModalVisible}
            orderType="B2C"
          />
        </Modal>
      )}
      <Modal
        title="Edit Order"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
        width="900px"
      >
        <EditOrder
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          form={form}
          id={id}
          editOrderId={editOrderId}
          fetchOrders={() => fetchOrders(getEmailForFetch())}
          setLoading={setLoading}
          setIsEditModalVisible={setIsEditModalVisible}
        />
      </Modal>

      <Modal
        title="Order Details"
        visible={viewOrderModalVisible}
        onCancel={handleCloseViewOrderModal}
        footer={null}
      >
        <ViewOrder
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          getStatusColor={getStatusColor}
          handleDownloadOrder={handleDownloadOrder}
          handleEstiDownloadOrder={handleEstiDownloadOrder}
        />
      </Modal>
    </AppLayout>
  );
};

export default Order;
